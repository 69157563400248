require('../bootstrap');
const {transform} = require("lodash");

$(document).ready(function(){

    $(".hamburger").click(function(){
        $(".hamburger").toggleClass("is-active");
        $('.nav-links').toggleClass("show");
        $('body').toggleClass("menu-open");
        $('.mainnav').toggleClass("menu-open");
    });

    if(window.location.href.indexOf("her2-low-spiel/index") > -1){
        document.body.classList.add('index-page');
    }


    var websiteName = "astrazeneca";
    var cards = document.querySelectorAll("a");
    var modal = document.getElementById("redirectModal");

    for (var i = 0; i < cards.length; i++) {
        var card = cards[i];
        card.onclick = function () {
            //console.log("enter this.element: " + this.toLocaleString() + " and this.link: " + this.getAttribute("href") + " and card: " + card.getAttribute("href"));
            if(this.toLocaleString().includes("astrazeneca")==false
                && !this.toLocaleString().includes("storyblok")
                && !this.toLocaleString().includes("atemwege")
                && !this.toLocaleString().includes("mailto")
                && !this.toLocaleString().includes("eigenart")
                && !this.toLocaleString().includes("her2ihc")
                && document.getElementById("redirectModal").classList.contains("modal-open")==false
                && this.toLocaleString().includes("cookie")==false)
            {
                modal.style.display = "block"; //open modal
                document.getElementById("continueButtonRedirectModal").setAttribute("href", this.toLocaleString()); //setting the redirect link
                document.getElementById("redirectModal").classList.add("modal-open");
                return false; //override standard handling
            }
        }
    }

    // Get elements that closes the modal
    var span = document.getElementsByClassName("redirect-modal-close")[0];
    var cancelButton = document.getElementById("cancelButtonRedirectModal");

    // When the user clicks, close the modal
    span.onclick = function (){
        closeModal();
        return false; //override standard handling
    }
    cancelButton.onclick = function() {
        closeModal();
        return false; //override standard handling
    }

    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function(event) {
        if (event.target == modal) {
            modal.style.display = "none";
            document.getElementById("redirectModal").classList.remove("modal-open");
        }
    }

    function closeModal(){
        modal.style.display = "none";
        document.getElementById("redirectModal").classList.remove("modal-open");
    }

});


